@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins-Regular.ttf");
    font-weight: 200;
}
* {
    box-sizing: border-box;
}

body {
    display: flex;
    background-color: #f6f5f7;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family:'Poppins' , sans-serif ;
    overflow: hidden;
    height: 100vh;
}

h1 {
    font-weight: 700;
    letter-spacing: -1.5px;
    margin: 0;
    margin-bottom: 15px;
}

h1.title {
    font-size: 45px;
    line-height: 45px;
    margin: 0;
    text-shadow: 0 0 10px rgba(16 ,64 , 74 , 0.5);
}
p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
    text-shadow: 0 0 10px rgba(16 ,64 , 74 , 0.5);
}

span {
    font-size: 14px;
    margin-top: 25px;
}

a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
    transition: 0.3x ease-in-out;
}
a:hover {
    color: #4bb6b7;
}

.content {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: space-around;
}

.content .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content input {
    accent-color: #333;
    width: 12px;
    height: 12px;
}

.content label {
    font-size: 14px;
    user-select: none;
    padding-left: 5px;
}

button {
    position: relative;
    border-radius: 20px;
    border: 1px solid #4bb6b7;
    background-color: #4bb6b7;
    color: #fff;
    font-size: 15px;
    font-weight: 900;
    margin: 10px;
    padding: 12px 80px;
    letter-spacing: 1px;
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
}

button:hover {
    letter-spacing: 3px;
}

button:active{
    transition: scale(0.95);
}

button:focus {
    outline: none;
}

button.ghost {
    background-color: rgba(225, 225, 225, 0.2);
    border: 2px solid #fff;
    color: #fff;
}
button.ghost i{
  position: absolute;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
button.ghost i.register{
    right: 70px;
}

button.ghost i.login{
    left: 70px;
}

button.ghost:hover i.register{
    right: 40px;
    opacity: 1;
}

button.ghost:hover i.login{
    left: 40px;
    opacity: 1;
}

form {
    background-color:#fff ;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

input {
    background-color: #eee;
    border-radius: 10px;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.containter {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0px 14px 28px rgba(0 ,0 , 0 , 0.25), 0 10px 10px rgba(0 ,0 , 0 , 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 500px;
}

.form-containter {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.login-containter {
    left: 0;
    width: 50%;
    z-index: 2;
}

.containter.right-panel-active .login-containter {
    transform: translateX(100%);
}

.register-containter {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.containter.containter.right-panel-active .register-containter {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {
    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

.overlay-containter {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.containter .right-panel-active .overlay-containter {
    transform: translate(-100%);
}

.overlay {
    background-image: url("../public/images/image.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(
        to top,
        rgba(46 , 94 , 109, 0.4) 40%,
        rgba(46 , 94 , 109, 0)
    );
}

.containter.containter.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 100%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
   transform: translateX(-20%); 
}

.containter.right-panel-active .overlay-left {
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    left: 20vh;
    transform: translateX(0); 
 }
 
 .containter.right-panel-active .overlay-right {
     transform: translateX(20%);
 }

 .soical-containter {
    margin: 20px 0;
 }
 
 .soical-containter {
  
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    height: 40px;
    width: 40px;
    transition: 0.3s ease-in-out;
 }

 .soical-containter a {
    margin-left: 10px;
 }

 .soical-containter a:hover {
    border: 1px solid #4bb6b7;
 }